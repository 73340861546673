<template>
  <div id="add-content">
    <v-card>
      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{card_header_props.header.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
            <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog
          v-model="card_header_props.helper.page_helper"
          width="500"
          :active.sync="card_header_props.helper.page_helper"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form')}}</v-card-title>

            <v-card-text>{{ this.$t('_course_list._info.content') }}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ this.$t('_common.OK') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->
      <v-col v-if="lessons.length > 0">
      <v-container class="mx-0 col-12">
        <v-row>
          <v-col cols="12" md="6" lg="4" v-bind:key="lesson.Id" v-for="lesson in lessons">
            <v-flex style="padding-top: 1rem; padding-bottom: 3rem">
              <v-sheet class="mx-auto" elevation="8" style="width:90%;">
                <div class="pa-5 elevation-2 mb-1 text-center" style="background-color: rgb(71 137 231);">
                  <h3
                    class="font-semibold white--text"
                  >{{lesson.Title}}</h3>
                </div>

                <v-carousel v-if="lesson.Children.length > 0" center-active show-arrows height="400">
                  <v-carousel-item
                    v-for="course in lesson.Children"
                    :key="course.Id"
                    style="cursor:pointer" @click="onClickCourse(course.Id)"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-flex :class="{'flex-hover': hover}" class="mx-auto d-flex flex-column justify-space-between align-center" style="height: 100%">
                              <p  class="font-semibold" style="color: black; font-size: 15px; padding: 0.5rem">{{ course.Title }} </p>
                          <div v-if="course.ImageUrl != null" style="display: flex; align-items: center; max-width: 100%; min-height: 100%; height: 100%; position: relative">
                            <v-img
                              :src="course.ImageUrl"
                            >
                              <v-avatar size="25"></v-avatar>
                            </v-img>
                            <v-avatar
                              v-if="hover"
                              color="white"
                              size="36"
                              class="centered"
                              style="position: absolute; top:0; bottom: 0; left: 0; right: 0; margin: auto; box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); z-index: 2;"
                            >
                              <v-icon color="black">mdi-play</v-icon>
                            </v-avatar>
                          </div>
                          <div v-else-if="lesson.ImageUrl != null" style="display: flex; align-items: center; max-width: 100%; height: 100%; position: relative">
                            <v-img
                              :src="lesson.ImageUrl"
                            >
                              <v-avatar
                                v-if="hover"
                                color="white"
                                size="36"
                                class="centered"
                                style="position: absolute; top:0; bottom: 0; left: 0; right: 0; margin: auto; box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); z-index: 2;"
                              >
                                <v-icon color="black">mdi-play</v-icon>
                              </v-avatar>
                              <v-avatar size="25"></v-avatar>
                            </v-img>
                          </div>
                        <v-img
                          v-else
                          src="/assets/noimage.png"
                        >
                          <v-avatar
                            v-if="hover"
                            color="white"
                            size="36"
                            class="centered"
                            style="position: absolute; top:0; bottom: 0; left: 0; right: 0; margin: auto; box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); z-index: 2;"
                          >
                            <v-icon color="black">mdi-play</v-icon>
                          </v-avatar>
                          <v-avatar size="25"></v-avatar>
                        </v-img>
                  
                      </v-flex>
                    </v-hover>
                  </v-carousel-item>
                </v-carousel>

                <v-card v-else class="mx-auto">
                  <v-card-text>
                    <p class="display-1 text--primary text-center">{{ $t('_course_list.No_course_found') }}</p>
                    <p
                      class="text--primary text-center"
                    >{{ $t('_course_list.No_course_found_Text') }}</p>
                  </v-card-text>
                </v-card>

              </v-sheet>

            </v-flex>
          </v-col>
        </v-row>
      </v-container>
      </v-col>
      <v-col v-else>
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <p class="display-1 text--primary text-center">{{ $t('_course_list.No_lesson_found') }}</p>
            <p
            >{{ $t('_course_list.No_lesson_found_Text') }}</p>
          </v-card-text>
        </v-card>
      </v-col>

    </v-card>
    <!--    </v-app>-->
  </div>
</template>

<script>
require("../../assets/css/neo.css");
const TOPIC_TYPE_LESSON = 3;

export default {
  data() {
    return {
      model: null,
      lessons: [],
      card_header_props: {
        header: {
          headLine: this.$t('_verticalNavbar.Course_List'),
          subTitle: this.$t('_course_list.Attend_Leson_Text'),
          icon: "import_contacts"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      },
      search: null
    };
  },

  methods: {
    init() {
      this.$goc.request.get(
        this.$enums.API.Topic_User +
          this.activeUserInfo.Uid +
          "/" +
          TOPIC_TYPE_LESSON,
        response => {
          this.lessons = response.Result.Topics;
        }
      );
    },
    onClickCourse(topicId) {
      this.$router.push({ name: "course-view", params: { topicId: topicId } });
      // this.$goc.console.log("Clicked", topicId);
    }
  },
  props: {
    source: String
  },
  components: {},
  mounted: function() {
    this.init();
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {}
};
</script>


<style lang="scss">
.video-js .vjs-tech {
  position: inherit;
}

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

.scroll-area {
  position: relative;
  margin: auto;
  width: auto;
  height: 18rem;
}
</style>

<style lang="scss" scoped>
  .flex-hover {
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.1);
      z-index: 1;
    }
  }
</style>
